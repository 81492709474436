
.scene-capture{
   
    overflow:scroll;
    padding-top:100px;
    width: 85%;
    margin:auto;
    position: relative;
    top: 20px;
    min-height: 100vh;
    height:100%;
}
.scene-capture::-webkit-scrollbar {
    width: 0px;
    display: none;
}
 
.scene-capture::-webkit-scrollbar-thumb {
    background: transparent;
}

.scene-capture div div {
    display:flex;
}
.scene-capture div div div{
    min-width: 110px;
    min-height:110px;
    width: 110px;
    height:110px;
    border: 1px dotted rgb(168, 168, 168);
    cursor:pointer;
    text-align:center;
}

.scene-capture div div div img{
    width: 106px;
    max-height:106px;
    margin:auto
}

.scene-capture div div div input[type="file"]{
    width: 106px;
    height:106px;
    opacity:0;
    cursor:pointer;
}


.scene-capture div div div.active{
    border: 1px dotted #0d6efd;;
    position: relative;
    z-index: 2;
}

.scene-capture button{
    position:absolute;
    margin-top: 50px;
    left: 85px;
    top:-18px;
}

.scene-capture button:nth-child(2){
    position:absolute;
    margin-top: 50px;
    left: -12px;
    top:-20px;
}

@media only screen and (min-width: 768px) {
    .scene-capture button{
        left: 100px;
        top:-18px;
    }
    
    .scene-capture button:nth-child(2){
        left: -10px;
        top:-20px;
    }
}


