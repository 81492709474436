.patient-items-align {
	padding-top: 0;
}

.breadcrumb {
	padding: 1.2em 2em;
	margin-bottom: 0;
	background-color: #f2f2f2;
}

.breadcrumb a,
.breadcrumb li {
	font-size: 12px;
}

.assessment-wrapper {
	padding: 1em;
}

.layout-boxed .card {
	margin-bottom: 9px !important;
}

.assessment-wrapper .card {
	margin-bottom: 1em;
	-webkit-border-bottom-right-radius: 15px !important;
	-webkit-border-bottom-left-radius: 15px !important;
	-moz-border-radius-bottomright: 15px !important;
	-moz-border-radius-bottomleft: 15px !important;
	border-bottom-right-radius: 15px !important;
	border-bottom-left-radius: 15px !important;
	-webkit-box-shadow: 2px 2px 6px -1px rgba(184, 178, 184, 1) !important;
	-moz-box-shadow: 2px 2px 6px -1px rgba(184, 178, 184, 1) !important;
	box-shadow: 2px 2px 6px -1px rgba(184, 178, 184, 1) !important;
	margin-bottom: 9px !important;
	padding-top: 1em !important;
}

.assessment-wrapper #inner-area {
	height: 75vh;
	overflow-y: scroll;
	padding-right: 1em;
}

.assessment-wrapper .form-check-input {
	position: relative;
}

.assessment-wrapper form p {
	font-weight: 800;
	display: flex !important;
	line-height: 2em !important;
}

.assessment-wrapper form p div {
	margin-right: 0.5em;
}

/* width */

.assessment-wrapper #inner-area::-webkit-scrollbar {
	width: 15px;
	height: 5px;
}

/* Track */

.assessment-wrapper #inner-area::-webkit-scrollbar-track {
	background: #ffffff;
}

/* Handle */

.assessment-wrapper #inner-area::-webkit-scrollbar-thumb {
	background: #b7b7b7;
}

/* Handle on hover */

.assessment-wrapper .left-area {
	padding: 1em 2em;
}

.assessment-wrapper #inner-area::-webkit-scrollbar-thumb:hover {
	background: #999;
	background: rgb(240 235 248);
}

.assessment-wrapper .right-area {
	padding: 0px 4.5em;
}

.assessment-wrapper .right-area .btn-group-lg > .btn,
.assessment-wrapper .right-area .btn-lg {
	padding: 1rem 1rem;
	font-size: 1em !important;
	margin: 14px 0px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	background: rgb(103, 58, 183);
	width: 85%;
}

.assessment-wrapper .right-area .btn-outline-secondary {
	color: #676b6f !important;
	background-color: transparent;
	background-image: none;
	border-color: #bfbfbf !important;
}

.assessment-wrapper .right-area .btn-outline-secondary:hover {
	color: #000 !important;
	background: rgb(240 235 248);
}

.assessment-wrapper .right-area .assessment-wrapper .right-area .btn.focus,
.assessment-wrapper .right-area .btn:focus {
	color: #ffffff;
}

/*********** scroller group *********/

.assessment-wrapper .scroller-group > img {
	position: absolute;
	right: 3.6em;
	width: 1em;
	cursor: pointer;
}

.assessment-wrapper .scroller-group .top-scroller {
	top: 1em;
}

.assessment-wrapper .scroller-group .bottom-scroller {
	bottom: 1em;
}

.bottom-button-group {
	padding: 20px 0px;
}

.bottom-button-group .submit-reset-btn {
	float: right;
	padding-right: 0.9em;
}

.assessment-wrapper ::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #b7b7b7;
}

.assessment-wrapper ::-moz-placeholder {
	/* Firefox 19+ */
	color: #b7b7b7;
}

.assessment-wrapper :-ms-input-placeholder {
	/* IE 10+ */
	color: #b7b7b7;
}

.assessment-wrapper :-moz-placeholder {
	/* Firefox 18- */
	color: #b7b7b7;
}

@media (max-width: 1366px) {
	.bottom-button-group .submit-reset-btn {
		padding-right: 2.5em;
	}
	.btn-somatus-outline {
		padding: 8px 34px;
	}
	.assessment-wrapper .right-area {
		padding: 0px 2em;
	}
}

/* 
	##Device = Most of the Smartphones Mobiles (Portrait)
	##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 767px) {
	.bottom-button-group .submit-reset-btn {
		padding-right: 1.4em;
	}
	.btn-somatus-outline {
		padding: 8px 25px;
	}
	.assessment-wrapper .scroller-group > img {
		right: 0.8em;
	}
	.assessment-wrapper .assessment-status .row .col-sm-3 {
		border-right: 1px solid transparent;
		border-bottom: 1px solid #e4e4e4;
	}
	.assessment-wrapper .assessment-status .row .col-sm-3:last-child {
		border-bottom: 1px solid transparent;
	}
	.header-title {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		font-size: .75em;
	}
}

@media screen and (max-width: 1024.1px) {
	.assessment-wrapper #inner-area {
		height: auto;
		overflow-y: hidden;
		padding-right: 0 !important;
	}
}

.assessment-wrapper select {
	font-size: 1em !important;
	border-radius: 0.25em !important;
	border: solid hsl(0, 0%, 80%) 1px !important;
	height: 39px !important;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	display: block;
	width: 100%;
}

.activities-createdBy {
	position: relative;
	left: 22px;
	margin-top: 0.2em;
}

.assessment-wrapper .header-class {
	margin-left: -10px;
	margin-right: -11px;
	margin-top: -14px;
	margin-bottom: 12px;
	padding-left: 14px;
}

.assessment-card {
	-webkit-border-bottom-right-radius: 15px !important;
	-webkit-border-bottom-left-radius: 15px !important;
	-moz-border-radius-bottomright: 15px !important;
	-moz-border-radius-bottomleft: 15px !important;
	border-bottom-right-radius: 15px !important;
	border-bottom-left-radius: 15px !important;
}

.extra-Input-Area {
	margin-bottom: 1em;
}

.assessment-details-view ul {
	display: flex;
}

.assessment-details-view ul li {
	color: #9da0aa;
	padding: 0em 1em;
	position: relative;
	display: grid;
	line-height: 1.5;
}

.assessment-details-view ul li label {
	margin-bottom: 0;
	color: #9e9999de;
	font-weight: 400;
}

.assessment-details-view ul li::after {
	content: "";
	width: 1px;
	background: #e2e2e2;
	height: 100%;
	position: absolute;
	top: 0px;
	right: -2px;
}

.assessment-details-view ul li:last-child::after {
	width: 0;
	background: transparent;
}

.assessment-details-view ul li p {
	color: #818181;
	padding-right: 0em;
	font-weight: bold;
	font-size: 1em !important;
	font-weight: bold;
}

.assessment-details-view ul li > .commentBox {
	height: 4em !important;
	width: 100% !important;
}

.assessment-wrapper .right-area .btn:focus {
	color: #ffffff;
}

.assessment-child-textbox {
	width: 98% !important;
}

.assessment-textarea {
	height: 4em !important;
}

.w-15 {
	width: 15%;
}
.w-30 {
	width: 30%;
}
.w-55 {
	width: 55%;
}

.seperator {
	border-bottom: 1px solid #cecbcb;
	margin-bottom: 0.75em;
	padding-bottom: 0.75em;
}

.assementAnswer .image-container{
	margin-right: 2em;
	margin-bottom: 0.5em;
}

.scene-capture-container{
	max-height: 200px;
	overflow-X:scroll;
	display:flex;
	flex-direction:horizontal;
}