:root {
  --primary: #1565c0;
  --success: #00c853;
  --success-deep: #2e7d32;
  --blair: #999696;
}

body {
  background: #fff;
}

.survey-container {
  max-width: 720px;
}

.survey-inner-cont {
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
  margin: 1em 0.5em;
  border-radius: 5px;
}

.fw-500 {
  font-weight: 500;
}

.survey-header {
  border-bottom: 1px solid grey;
  padding: 0.5em;
  font-size: 1.5em;
}

.text-right {
  text-align: right;
}

.survey-form {
  padding: 1em 2em;
}

.submit-btn {
  background-color: #673ab7;
}

.mrb-3 {
  margin-bottom: 0.75rem;
}

.hearder {
  background-color: #6339ad !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 10%) !important;
  transition: left 0.3s ease-in-out !important;
  font-size: 2em !important;
  color: rgb(133, 119, 119) !important;
  z-index: 99 !important;
  text-align: center !important;
}

.btn-somatus-outline {
  background-color: #888888;
  border-radius: 20px;
  padding: 10px 42px;
  margin-left: 1em;
  float: right;
}

.btn-somatus-outline.nobg {
  border: 1px solid rgb(165, 164, 164);
  color: #888888;
}


.btn-somatus-outline.active {
  background-color: rgb(103, 58, 183);
  color: #ffffff;
}

.btn-somatus-outline:hover {
  background-color: rgb(103, 58, 183) !important;
  color: #ffffff !important;
}

.btn-somatus-outline.nobg:hover {
  background-color: transparent !important;
  color: #000000 !important;
  border: 1px solid rgb(165, 164, 164) !important;
}

.cancel {
  background-color: #888888 !important;
  border: 1px solid #888888 !important;
  border-radius: 50px !important;
  color: #fff !important;
}

.cancel:hover {
  background-color: rgb(103, 58, 183) !important;
}

.save {
  border-radius: 50px !important;
}

.save:hover {
  background-color: rgb(103, 58, 183) !important;
}

.overflow-hidden {
  overflow: hidden;
}

.required-color-red {
  color: red;
}

.image-container {
  height: 10vh;
  width: 30%;
  object-fit: cover;
  display: inline-block;
  position: relative;
  margin-top: 1em;
  margin-right: 1em;
}

.image-container img {
  width: 76px;
  max-height: 80px
}


.object-fit-image {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.input--file {
  position: relative;
  top: 3px;
}



.input--file label {
  position: relative;
  top: -3px;
}
.imageCountWraper{
  min-width:45%;
  max-width:45%;
}

.input--file.image-count label{
  left: 0.4rem;
}

.input--file input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
}

.header-logo-img {
  background-image: url("./Assets/images/logo.png");
  height: 1.6em;
  width: 4em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  margin-top: 7px;
  margin-left: 14px;
}



.ml-2 {
  margin-left: 0.5em;
}

.input--file {
  .disbale-img {
    color: #e9ecef !important;
  }
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: all 0.5s;

  .card {
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.2),
      0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  }
}

.min-vh-100 {
  min-height: 100vh !important;
}

.app-main {
  position: relative;
  min-height: 100vh;

  @media (max-width: 959.98px) {
    min-height: 75.4vh;
  }
}

.flex-column {
  flex-direction: column !important;
}

.img-fluid {
  max-width: 75%;
  margin: auto;

  @media (max-width: 1199.98px) {
    max-width: 50%;
    padding-bottom: 2em;
  }
}

.nav li .nav-link {
  padding-left: 0 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.display-3 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.login-button {
  color: #fff;
  background-color: #5383ff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.2),
    0px 1px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: normal;
  line-height: 1.75;
  border-radius: 0.5rem;
  letter-spacing: 0.02857em;
  text-transform: none;
  min-width: 7em;
}

.text-black-50 {
  color: rgba(7, 9, 25, 0.5) !important;
}

.app-content--inner {
  overflow: auto;
  padding: 2rem 2.25rem 1rem;
  display: flex;
  flex: 1 1;
}

.app-content {
  flex: 1;
  display: flex;
  padding: 0;
  flex-direction: column;
  width: 100%;

  &--inner {
    display: flex;
    flex: 1;

    @media (max-width: 959.98px) {
      padding: 1.1rem 1.313rem 1rem 1.5rem;
    }

    &__wrapper {
      display: block;
      width: 100%;
    }
  }
}

.font-size-lg {
  font-size: 1.1875rem;
}

.logout {
  width: 100%;
  text-align: right;
  padding-right: 0.5em;
  margin-top: -0.25em;

  svg {
    cursor: pointer;
  }
}

.loader {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 99;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-tap-highlight-color: transparent;
}

.list-group {
  padding: 0.8em 0;
}

.list-group-item-text {
  margin-bottom: 0;
  font-size: 0.9em;
  color: #615f5f;
}

.searchbox {
  width: 100%;
  padding: 0.7em;
  margin-top: 1em;
}

.location {
  margin: 1em;
  font-size: 1.2em;
  font-weight: 500;
}

.app-header-logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-height: 2em;
  padding-left: 0.5em;
}

.click-link {
  color: #4b7dc7 !important;
  cursor: pointer;

  font-size: 1.2rem !important;
}

.mui_list-group-item {
  border: 1px solid rgba(0, 0, 0, .125);
  width: 93%;
  color: #212529;
  display: block;
  padding: 0.5rem 1rem;
  margin: 0px 0.2rem 0.5rem 0.2rem;
  position: relative;
}

.mui_list-group-item:nth-last-child {
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.float-right {
  float: right;
}

.title-div {
  background: #c1c1ec;
  padding: 0.7em;
  margin-bottom: 1em;
  font-weight: 600;
}

.image-planogram {
  padding-left: 1.5em;
  cursor: pointer;
}

.image-div {
  display: flex;
  align-items: center;
  width: 100%;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.header-logo {
  max-height: 60px;
  width: auto;
  max-width: 120px;
}


.selected-image-popup {
  width: 100%;
  height: auto;
  max-height: 30em;
}

.survey-type {
  .list-group {
    max-height: 30em;
    overflow-y: auto;
  }
}

.view-survey-link {
  font-size: 0.8em;
  color: #4b7dc7 !important;
  cursor: pointer;

}

.survey-view-image {
  max-height: 10vh;
  max-width: auto;
}

.survey-view-image.barcode {
  height: 105px;
  min-width: 205px;
  max-height: 20vh;
}

.forgot-password .form-group {
  margin-bottom: 1rem;
}

.ml-3 {
  margin-left: 1rem;
}

.cursor {
  cursor: pointer;
}

.header-user {
  margin-top: -2em;
}

.coolr-user {
  margin-top: -2.5em;
}

.header-user-section {
  max-height: 6.5em;
}

.dropdown-item {
  line-height: 2em;
  align-items: center;
  display: flex;
  justify-content: center;
}

.connectivity-status {
  font-size: 10px;
  position: absolute;
  top: 4.75em;
  right: 5em;
  line-height: 10px;

}

.french-lang-styles .connectivity-status-dot {
  left: -14px;
  position: relative;
}

.connectivity-status-dot {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff;
  position: relative;
  top: 7px;
}

.profile-area {
  cursor: pointer;
}

.profile-area span {
  font-size: 1rem;
  color: #fff;
  display: inline-block;
}

.online-dot-color {
  background-color: #1bc943;
}

.offline-dot-color {
  background-color: #ff3b30;
}

.connectivity-status-dot.shift-right {
  top: 0px;
  left: -10px
}

.multi-img-popup {
  height: auto;
}

.multi-img-popup .sr-only {
  display: none;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  width:100%!important
}

.wrap-word-3-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

#interactive.viewport {
  position: relative;
}

#interactive.viewport>canvas,
#interactive.viewport>video {
  max-width: 100%;
  width: 100%;
}

#interactive.viewport>canvas,
#interactive.viewport>video {
  max-width: 100%;
  width: 100%;
}

canvas.drawing,
canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}

.version {
  height: 2em;
  font-size: 13px;
  font-weight: 300;
  text-align: center;
}

.floating-menu {
  position: fixed;
  right: 1em;
  bottom: 1em;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}

.floating-menu-icon {
  display: inline-block;
  width: 56px;
  height: 56px;
  line-height: 56px;
  vertical-align: middle;
  background: #6339ad;
  border-radius: 50%;
  border: none;
  text-align: center;
}

.label-disable-color {
  color: #b5b5b5;
}

.disbale-img {
  color: #e9ecef !important;
}

.header-title {
  font-size: 1.2rem !important;
  color: #fff !important;
}


.bottom-action-button {
  height: 3rem;
  margin-top: 10px;
}

.bottom-action-button button {
  width: 50%;
  height: 100%;
  border-radius: 0px !important;
  font-size: 0.7rem;
}

.bottom-action-button button:nth-child(1) {
  background: #6339ad;
}

.bottom-action-button button:nth-child(2) {
  background: #4b7dc7 !important;
}


.language-wraper {
  position: relative;
  top: 0.5rem;
}

.language-wraper select,
.language-wraper option {
  background-color: transparent;
  border: 0px solid transparent;
  outline: none;
}


.hide-question {
  display: none !important;
}

.show-question {
  display: block !important;
}

.disable-submitted-btn button:nth-child(1) {
  background-color: #8869bf;
  color: #cdcbcb;
}

.survey-questions input[type=text],
input[type=textarea],
input[type=number] {
  width: 98% !important;
}

.survey-questions input[type=text],
.survey-questions input[type=number],
.survey-questions input[type=textarea] {
  font-size: 17px !important;
}

.survey-questions input[type=text] {
  padding: 3px 0px;
}


.chose-scanner-type label {
  position: relative;
  top: -6px;
  left: 3px;
}

.drop-down {
  width: 100%;
  padding: 18px 5px;
  border-radius: 1px;
  font-size: 17px !important;
}

.progress-bar {
  width: 85%;
  position: absolute;
  top: 55%;
  left: 10%;
  align-items: center;
}

.progress-bar>div:nth-child(1) {
  width: 100%;
  display: flex;
  align-items: center;
}

.progress-bar>div:nth-child(1)>div:nth-child(1) {
  width: 100%;
  margin-right: 8px
}

.progress-bar>div:nth-child(1)>div:nth-child(2) {
  min-width: 35px
}

.progress-bar>div:nth-child(2) {
  background: #7195ba;
  border-radius: 4px;
  padding: 3px 5px 3px 10px;
  margin-right: 42px
}

.progress-bar>div:nth-child(2) p {
  font-size: 13px;
  color: #fff;
}

.img-canvas {
  display: none;
}

.barcode-scanner {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 99%;
  height: 90vh;
  overflow: hidden;
  background: black;
  padding-top: 60px;
}

.barcode-closebtn {
  z-index: 100;
  position: absolute;
  top: 5px;
  right: 3px;
  width: 3rem;
  height: 3rem;
  background: none;
  color: white;
  border-radius: 10px;
  border: none;
  font-size: 1.5rem
}

.drawingBuffer {
  position: absolute;
  top: 30%;
  left: 2px;
  width: 100%;
}

.barcode-wrapper {
  display: flex;
  flex-direction: column;
}

.barcode-wrapper input[type="file"] {
  width: 30px;
  height: 40px;
  position: relative;
  left: -25px;
  top: -8px;
  z-index: 1;
  opacity: 0;
}

.barcode-wrapper select {
  position: relative;
  top: -7px;
  left: 4px;
  min-width: 80%;
}

.barcode-wrapper label {
  color: #e94444;
  font-size: 12px;
  font-weight: bold;
  position: relative;
  top: -20px;
}

.barcode-wrapper img {
  width: 100px;
  height: 100px;
  margin: 15px 0 15px 0;
}

#info-box.show {
  display: block;
}

#info-box.hide {
  display: none;
}

.location-wrapper {
  overflow-x: hidden;
}

.app-title {
  flex-direction: column;
  position: relative;
}

.app-title p {
  margin: 0px;

}

.app-version-font {
  font-size: 12px !important;
  font-weight: bold;
}

.capture-scene-title {
  font-size: 15px !important;
  color: #fff !important;
  font-weight: bold !important;
}

.tab-status {
  position: relative;
}

.tab-status.untouched {}

.tab-status.completed {
  background-color: var(--success);
}

.tab-status.started {
  color: var(--primary);

}


.location-tab .Mui-focusVisible {
  background-color: transparent;
}

.scannerDialog .MuiPaper-root {
  background: black;
}

.react-swipeable-view-container div:nth-child(1)::-webkit-scrollbar {
  display: none;
}

.react-swipeable-view-container>div {
  overflow-y: hidden !important;
}

ul.MuiList-root.MuiList-padding.css-h4y409-MuiList-root:focus-visible {
  outline: transparent auto 0px;
}

.MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
  justify-content: flex-end !important;
}

.MuiAccordionSummary-root.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.survey-box {
  position: relative;
  top: 2rem;
}

@media screen and (max-width: 480px) {
  .header-logo-img {
    background-image: url("./Assets/images/coca-cola-mini.png");
    height: 1.5em;
    width: 1.5em;
  }

  .assessment-wrapper .left-area {
    padding: 1em !important;
  }

  .image-container {
    width: 26% !important;
  }

  .mui_list-group-item {
    width: 88%;
  }

  .french-lang-styles .connectivity-status-dot>span {
    width: 55px;
    top: -3px;
    display: block;
    left: 1px;
    margin-left: 11px;
  }


  .forgot-password-cancel {
    height: 3.8rem;
  }
}

@media screen and (min-width: 600px) {
  .mui_list-group-item {
    width: 95%;
  }
}

@media screen and (min-width: 900px) {
  .mui_list-group-item {
    width: 95%;
  }


  .forgot-password-cancel {
    height: -8.2rem;
  }

  .map-icon {
    position: relative;
    left: -65px;
  }
}

@media screen and (min-width: 1020px) {
  .mui_list-group-item {
    width: 97%;
  }
}

@media screen and (min-width: 1560px) {
  .mui_list-group-item {
    width: 98%;
  }
}


.swipper {
  animation: barcode-animation 1.5s infinite;
  height: 1px;
  box-shadow: 2px 2px 4px 4px rgb(255, 54, 54);
  border: none;
  position: relative;
  top: -2px;
  animation-fill-mode: forwards;
  width: 100%;
}

@keyframes barcode-animation {
  0% {
    top: 0px
  }

  2% {
    top: 8px
  }

  4% {
    top: 16px
  }

  6% {
    top: 24px
  }

  8% {
    top: 32px
  }

  10% {
    top: 40px
  }

  12% {
    top: 48px
  }

  14% {
    top: 56px
  }

  16% {
    top: 64px
  }

  18% {
    top: 72px
  }

  20% {
    top: 80px
  }

  22% {
    top: 88px
  }

  24% {
    top: 96px
  }

  26% {
    top: 104px
  }

  28% {
    top: 112px
  }

  30% {
    top: 120px
  }

  32% {
    top: 128px
  }

  34% {
    top: 136px
  }

  36% {
    top: 144px
  }

  38% {
    top: 152px
  }

  40% {
    top: 160px
  }

  42% {
    top: 168px
  }

  44% {
    top: 168px
  }

  46% {
    top: 176px
  }

  48% {
    top: 184px
  }

  50% {
    top: 192px
  }

  52% {
    top: 202px
  }

  54% {
    top: 200px
  }

  56% {
    top: 192px
  }

  58% {
    top: 184px
  }

  60% {
    top: 176px
  }

  62% {
    top: 168px
  }

  64% {
    top: 160px
  }

  66% {
    top: 152px
  }

  68% {
    top: 144px
  }

  70% {
    top: 136px
  }

  72% {
    top: 128px
  }

  74% {
    top: 120px
  }

  76% {
    top: 112px
  }

  78% {
    top: 104px
  }

  80% {
    top: 96px
  }

  82% {
    top: 80px
  }

  84% {
    top: 72px
  }

  86% {
    top: 64px
  }

  88% {
    top: 56px
  }

  90% {
    top: 48px
  }

  92% {
    top: 40px
  }

  94% {
    top: 32px
  }

  96% {
    top: 24px
  }

  98% {
    top: 16px
  }

  99% {
    top: 8px
  }

  100% {
    top: -2px
  }
}

.hide-swipper {
  visibility: hidden;
}

.dialog-main {
  padding: 4px 4px !important;
}

.dialog-list {
  display: flex;
  justify-content: space-between;
}

.dialog-list-type {
  flex: 1.5;
}

.dialog-list-category {
  flex: 1;
}

.dialog-list-icon {
  flex: 0.5;
  text-align: right;
}

.dialog-list-icon-empty {
  flex: 0.5;
}

.long-address-handler {
  word-wrap: break-word;
  padding-right: 1rem;
}